import { Column } from '@material-table/core';
import { Avatar, Box, Button, Chip, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import DeviceAlarmCounter from '../DevicePanel/DeviceAlarmCounter';
import BlipChip from '@/components/Shared/Chips/BlipChip';
import TagIcon from '@mui/icons-material/Tag';
import { ROUTES } from '@/shared/constants';

import { FleetDevices } from './typings';

export const useDevicesTableColumns = (): Array<Column<FleetDevices.Device>> => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const renderModel = useCallback(
    (device: FleetDevices.Device) => (
      <Box>
        <Typography variant="subtitle1">{device.name}</Typography>
        <Box display="flex" gap={1} alignItems="center" sx={{ flexWrap: 'wrap' }}>
          <Chip icon={<TagIcon />} label={device.model} size="small" />
          <Chip icon={<TagIcon />} label={device.id} size="small" />
          {device?.brandName === 'quench' && (
            <Chip
              icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
              label={device.metaData?.deviceQnumber || t('qUnassigned')}
              size="small"
            />
          )}
          <Chip
            icon={<TagIcon sx={{ rotate: '270deg', width: '1rem' }} />}
            label={device.metaData?.manufacturingSerialNumber || t('manufacturingSerialNumberUnassigned')}
            size="small"
          />
        </Box>
      </Box>
    ),
    [t]
  );

  const renderStatus = useCallback(
    (device: FleetDevices.Device) => (
      <Box>
        {!device.status || Object.keys(device.status).length === 0 ? (
          t('notDefined')
        ) : device.status.connection?.online === true ? (
          <Chip
            avatar={
              <Avatar sx={{ bgcolor: 'transparent' }}>
                <Box
                  sx={(theme) => ({
                    height: '80%',
                    bgcolor: theme.palette.success.light,
                    width: '80%',
                    borderRadius: '50%',
                  })}
                />
              </Avatar>
            }
            label={t('connected')}
            size="small"
            color="success"
            variant="outlined"
          />
        ) : (
          <BlipChip label={t('disconnected')} size="small" color="error" variant="outlined" />
        )}
      </Box>
    ),
    [t]
  );

  const renderErrors = useCallback(
    (device: FleetDevices.Device) => <DeviceAlarmCounter serialNumber={device.id} data={device.status.errors} />,
    []
  );
  const renderAlarms = useCallback(
    (device: FleetDevices.Device) => <DeviceAlarmCounter serialNumber={device.id} data={device.status.alarms} />,
    []
  );
  const renderAction = useCallback(
    (device: FleetDevices.Device) => (
      <Button
        key={`buttonDevicesTable${device.id}`}
        variant="outlined"
        color="primary"
        onClick={() => navigate(`/fleet/devices/${device.id}`)}
      >
        {t('view')}
      </Button>
    ),
    [navigate, t]
  );

  /*
   * Since we do not have anymore the native search functionality in the table
   * i'm keeping for reference the old customFilterAndSearch and for the exportTransformer for the given field.
   * To be removed when the feature is implemented.
   */
  return useMemo(
    (): Array<Column<FleetDevices.Device>> => [
      {
        title: t('modelNameId'),
        width: 'max-content',
        cellStyle: { textAlign: 'left' },
        // customFilterAndSearch: (filter, rowData) => {
        //   console.log('in custom filter and search.');
        //   const normalizeString = (str: string) => str.replace(/\s+/g, '').toLowerCase();
        //   const filterLowerCase = filter.toLowerCase();
        //   const matchesName =
        //     typeof rowData.name === 'string' ? rowData.name.toLowerCase().includes(filterLowerCase) : false;
        //   const matchesModel =
        //     typeof rowData.model === 'string' ? rowData.model.toLowerCase().includes(filterLowerCase) : false;
        //   const matchesSerialNumber =
        //     typeof rowData.id === 'string' ? rowData.id.toLowerCase().includes(filterLowerCase) : false;
        //   const matchesDevicesQnumber =
        //     typeof rowData.metaData?.deviceQnumber === 'string'
        //       ? normalizeString(rowData.metaData.deviceQnumber).includes(filterLowerCase)
        //       : false;
        //   const matchesManufacturingSerialNumer =
        //     typeof rowData.metaData?.manufacturingSerialNumber === 'string' &&
        //     rowData.metaData.manufacturingSerialNumber.includes(filter);

        //   return (
        //     matchesName ||
        //     matchesModel ||
        //     matchesSerialNumber ||
        //     matchesDevicesQnumber ||
        //     matchesManufacturingSerialNumer
        //   );
        // },
        // exportTransformer: (device) => `
        //   ${device.name}\n
        //   ${device.id}
        //   ${device.metaData?.deviceQnumber ? `\n${device.metaData?.deviceQnumber}` : ''}
        //   ${device.metaData?.manufacturingSerialNumber ? `\n${device.metaData?.manufacturingSerialNumber}` : ''}`,
        field: 'model',
        render: renderModel,
      },
      {
        title: t('connectionStatus'),
        field: 'status',
        // customFilterAndSearch: (filter, rowData) => {
        //   const filterLowerCase = filter.toLowerCase();
        //   const isOnline =
        //     rowData.status && Object.keys(rowData.status).length > 0 && rowData.status.connection?.online === true;
        //   const connectedTranslation = t('connected').toLowerCase();
        //   const disconnectedTranslation = t('disconnected').toLowerCase();

        //   if (isOnline && connectedTranslation.startsWith(filterLowerCase)) {
        //     return true;
        //   } else if (!isOnline && disconnectedTranslation.startsWith(filterLowerCase)) {
        //     return true;
        //   }
        //   return false;
        // },
        // exportTransformer: (rowData) => {
        //   return rowData.status.connection.online ? t('connected') : t('disconnected');
        // },
        render: renderStatus,
        export: true,
      },
      /*
      Until further notice, the location must be filtered from the table.
      {
        title: t('location'),
        field: 'location',
        customSort: (a, b) => {
          const aValue = `${a.region?.code || ''}-${a.region?.name || ''}`.toLowerCase();
          const bValue = `${b.region?.code || ''}-${b.region?.name || ''}`.toLowerCase();
          if (aValue > bValue) {
            return 1;
          } else if (aValue < bValue) {
            return -1;
          }
          return 0;
        },
        customFilterAndSearch: (filter, rowData) => {
          const filterLowerCase = filter.toLowerCase();
          const locationString = `${rowData.region?.code ? rowData.region.code.toLowerCase() : ''} - ${
            rowData.region?.name ? rowData.region.name.toLowerCase() : ''
          }`;
          return locationString.includes(filterLowerCase);
        },
        exportTransformer: (device) => `${device.region?.code} - ${device.region?.name}`,
        render: (device) => (
          <Typography variant="subtitle1">
            {device.region?.code && device.region?.name ? `${device.region.code} - ${device.region.name}` : 'N/A'}
          </Typography>
        ),
      },
      */
      { title: t('version'), field: 'swVersion', width: '5%' },
      {
        title: t('errors'),
        field: 'errors',
        // exportTransformer: (device) => device.status.errors,
        render: renderErrors,
      },
      {
        title: t(ROUTES.ONETOOL_CHANNELS_ALARMS.fragment),
        field: 'alarms',
        width: 'max-content',
        // exportTransformer: (device) =>
        //   `NOT-CRITICAL: ${device.status.alarms.nonCritical}\nCRITICAL: ${device.status.alarms.critical}`,
        render: renderAlarms,
      },
      {
        title: t('actions'),
        field: 'actions',
        width: '5%',
        sorting: false,
        searchable: false,
        export: false,
        render: renderAction,
      },
    ],
    [renderAction, renderAlarms, renderErrors, renderModel, renderStatus, t]
  );
};
